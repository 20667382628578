import type { APILeadsGetLeadResponse } from '@lemonenergy/contract-schemas'
import distributionCompanies from '@lemonenergy/contract-schemas/lib/constants/distributionCompanies'

import type { BrazilianStatesKeys } from './geo'

export type DiscoList = Exclude<
  APILeadsGetLeadResponse['body']['billDisco'],
  undefined
>

export type DiscoDetail = {
  code: DiscoList
  name: string
  states: BrazilianStatesKeys[]
  displayName?: string
}

export const discoDetails = distributionCompanies as DiscoDetail[]

export const discoList = Object.fromEntries(
  discoDetails.map(disco => [disco.code, disco.displayName ?? disco.name]),
) as { [key in DiscoList]: string }
